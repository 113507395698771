<template>

    <v-list-item-group v-model="selected_items" multiple>

        <v-list-item class="mediumdense" :value="0">
            <v-list-item-action>
                <v-icon>
                    {{ selected_items.some(a => a === 0) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                </v-icon>
            </v-list-item-action>

            <v-list-item-content>
                True
            </v-list-item-content>
        </v-list-item>

        <v-list-item class="mediumdense" :value="1">
            <v-list-item-action>
                <v-icon>
                    {{ selected_items.some(a => a === 1) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                </v-icon>
            </v-list-item-action>

            <v-list-item-content>
                False
            </v-list-item-content>
        </v-list-item>

    </v-list-item-group>

</template>

<script>
    module.exports = {
        data: function () {
            return {
                selected_items: [],
            }
        },
        props: {
            item: Object,
            value: null,
        },
        created() {
            if (this.value && this.value.values)
                this.selected_items = this.value.values.map(a => a ? 0 : 1);
        },
        watch: {
            selected_items: function(newv) {
                // When all items are selected, we disable this filter
                if (newv.length == 2 || newv.length < 1)
                    this.$emit('change', null);
                else
                    this.$emit('change', {
                        filterType: "set",
                        values: newv.map(a => a == 1 ? false : true)
                    });
            }
        },
        methods: {
        }
    }
</script>

<style scoped>
    .verydense {
        max-height: 24px;
        min-height: 24px;
    }

    .mediumdense {
        max-height: 28px;
        min-height: 28px;
    }

</style>