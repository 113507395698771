<template>
    <v-container class="grey lighten-5">
        <v-row dense v-for="(item,i) in rules" :key="i">
            <v-col cols="12">
                <v-row dense>
                    <v-col cols="4">
                        <v-select outlined dense hide-details
                                  label="Condition"
                                  v-model="item.condition"
                                  :items="condition_types"
                                  :menu-props="{ offsetY: true }"
                                  @change="updateReport"></v-select>
                    </v-col>
                    <v-col cols="8">
                        <v-text-field outlined dense hide-details
                                      label="Value"
                                      v-model="item.value"
                                      :disabled="item.condition == 'empty' || item.condition == 'notempty' || item.condition == 'missing'"
                                      @input="updateReport"></v-text-field>
                    </v-col>
                </v-row>

                <v-row dense justify="center" v-if="i < 1">
                    <v-col cols="1">
                        <v-btn-toggle :value="item.combiner" @change="(value) => setItemCombiner(item, i, value)">
                            <v-btn text x-small title="And" value="AND">
                                AND
                            </v-btn>
                            <v-btn text x-small title="Or" value="OR">
                                OR
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import _ from 'lodash';

    export default {
        data: function () {
            return {
                rules: [{ condition: 'contains', value: '', combiner: undefined }],
                condition_types: [
                    { text: 'Equals', value: 'equals' },
                    { text: 'Does not equal', value: 'notEquals' },
                    { text: 'Contains', value: 'contains' },
                    { text: 'Does not contain', value: 'notcontains' },
                    { text: 'Begins with', value: 'beginswith' },
                    { text: 'Ends with', value: 'endswith' },
                    { text: 'Blank', value: 'empty' },
                    { text: 'Not blank', value: 'notempty' },
                    { text: 'Missing', value: 'missing' },
                ],
            }
        },
        props: {
            item: Object,
            value: null,
        },
        created() {
            const self = this;
            this.updateReportDebouncer = _.debounce(function () {
                //debugger;
                if (self.rules.length < 1 || self.rules.some(a => !a.value && a.condition != 'empty' && a.condition != 'notempty' && a.condition != 'missing')) {
                    // Either an empty list (shouldn't happen) or an empty value on the first rule means no filter
                    self.$emit('change', null);
                    return;
                }

                if (self.rules.length == 1)
                    // Single rule scenario is the simplest format (made popular by ag-grid)
                    self.$emit('change', {
                        filterType: "text",
                        type: self.rules[0].condition,
                        filter: self.rules[0].value,
                    });
                else
                    // Multiple rule scenario puts the conditions into an array
                    self.$emit('change', {
                        filterType: "text",
                        operator: self.rules[0].combiner,
                        conditions: self.rules.map(a => ({
                            type: a.condition,
                            filter: a.value,
                        }))
                    });

            }, 500);

            if (this.value && this.value.type) {
                this.rules = [{
                    condition: this.value.type,
                    value: this.value.filter,
                }];
            }
            else if (this.value && this.value.conditions && this.value.conditions.length == 2) {
                this.rules = [{
                    condition: this.value.conditions[0].type,
                    value: this.value.conditions[0].filter,
                    combiner: this.value.operator,
                }, {
                    condition: this.value.conditions[1].type,
                    value: this.value.conditions[1].filter,
                }];
            }
        },
        watch: {
            rules: function (newv) {
                this.updateReport();
            }
        },
        methods: {
            updateReport() {
                this.updateReportDebouncer();
            },
            setItemCombiner(item, index, value) {
                console.log(`setItemCombiner[${index}] to ${value}:`);
                console.log(JSON.stringify(item, null, 3));

                if (index == this.rules.length - 1) {
                    if (item.combiner === undefined && value !== undefined)
                        // When changing the last item, setting to a non-undefined value will add a new row to the rules array
                        this.rules.push({ condition: 'equal', value: '', combiner: undefined });
                }
                else if (index < this.rules.length - 1) {
                    if (value === undefined)
                        // When changing a non-last item to undefined, we will remove all following rules
                        this.rules.splice(index + 1, this.rules.length - index - 1);
                }

                item.combiner = value;
                this.updateReport();
            },
        }
    }
</script>

<style scoped>

</style>