import Vue from 'vue';
import BaseComponent from './baseFormMixin.jsx';
import { appSettings } from '@/Shared/appSettings.js';
import careHelpfulFunctions from '@/Application/careHelpfulFunctions.jsx';
const mdiIcons = () => import ('@/JavaScript/mdi/font/v5.9.55/icons');
const tcIcons = () => import('@/JavaScript/totalcareicons/v1/totalcareicons');

Vue.component('sform-iconpicker', {
    mixins: [BaseComponent],
    data: () => ({
        icons: [],
        filteredIcons: [],
        item_text: 'name',
        item_value: 'value',
        item_disabled: undefined,
        item_icon: null,
    }),
    props: {
    },
    created() {
        tcIcons().then(icons => { this.icons.push(...icons.default.map(i => {
            return {
                name: i.name,
                displayName: i.name + ' (CI)',
                value: 'careicon careicon-'+i.id,
            }
        })); this.setFilteredIcons(this.itemvalue);});
        mdiIcons().then(icons => { this.icons.push(...icons.default.map(i => {
            return {
                name: i.name,
                displayName: i.name + ' (MDI)',
                value: 'mdi-'+i.name,
            }
        })); this.setFilteredIcons(this.itemvalue); });
    },
    computed: {
        iconList: function() {
            return _.take(this.filteredIcons, 300);
        },
        currentItemIcon: function() {
            const item = this.iconList.find(i => i.value == this.itemvalue);
            return item ? this.getItemIcon(item) : '';
        },
    },
    methods: {
        getItemIcon(item) {
            return <v-icon>{item.value}</v-icon>
        },
        setFilteredIcons(filter) {
            if(!filter)
                filter = '';
            this.filteredIcons = careHelpfulFunctions.sort(this.icons.filter(i => i.value.toLowerCase().includes(filter.toLowerCase()) || i.name.toLowerCase().includes(filter.toLowerCase())), "name");
        }
    },
    render() {
        let scopedSlots = {
            message: ({ message }) => {
                return <translation-container context={this} value={message}></translation-container>
            },
            item: ({ item, on }) => {
                return (
                    <v-list-item
                        value={item}
                        on={on}>
                        <v-list-item-icon>
                            {this.getItemIcon(item)}
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                {item.displayName}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                );
            }
        };

        let input = h('v-autocomplete', {
            props: {
                class:"caption pa-0 ma-0" ,
                outlined: true,
                singleLine: true,
                dense: true,
                hideDetails: true,
                clearable: true,
                style:{ width: "100%" },
                menuProps:{ offsetY: true, auto: true },
                value: this.itemvalue,
                items: this.iconList,
                itemText:this.item_text,
                itemValue:this.item_value,
                itemDisabled: this.item_disabled,
                returnObject: false,
                hint: this.hintText,
                label: this.labelText,
                prependInnerIcon: this.itemvalue,
                persistentHint: appSettings.DebugTranslationPrefixSetting,
            },
            on: {
                'change': (value) => this.sync(value),
                'update:search-input': (value) => this.setFilteredIcons(value),
                'blur': this.onBlur,
                'focus': this.onFocus
            },
            scopedSlots: scopedSlots
        });
        

        if (this.appearance)
            input.componentOptions.propsData = { ...input.componentOptions.propsData, ...this.appearance };

        return input;
    }
});
