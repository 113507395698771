import { time } from "ag-charts-community";

import utils from '@/Shared/utils.jsx';
import filters from '@/Shared/filters.js';
import helps from '@/Application/careHelpfulFunctions.jsx';

const common = {
    constants: {
        DISPLAY_RESIZING: -1,
        DISPLAY_DYNAMIC_GRID: 0,
        DISPLAY_FLAT_GRID: 1,
        DISPLAY_CHART: 2,

        TAB_METRICS: 1,
        TAB_FILTERS: 2,
        TAB_CHART_SETTINGS: 3,
        TAB_ALL_IN_ONE: 4,
        TAB_MODEL: 5,

        PIVOT_HEADER_SEP: '|',

        MILLISECONDS_PER_DAY: 86400000,
        MILLISECONDS_PER_HOUR: 60 * 60 * 1000,
    },
    colors: [
        "#2DBC71", // Emerald
        "#E67E22", // Carrot
        "#3498DB", // Peter River
        "#9C88FF", // Amethyst
        "#4CE137", // Ufo Green
        "#FFF200", // Yellow
        "#FF9F45", // Melon
        "#F368E0", // Orchid
        "#FF9FF3", // Light Pink
        "#48DBFB", // Winter Sky
        "#1DD1A1", // Mint
        "#EE5253", // Pastel Red
        "#0ABDE3", // Sky Blue
        "#10AC84", // Pacific Green
        "#5F27CD", // Purple
        "#FF5733", // Reddish
        "#33FF57", // Greenish
        "#3357FF", // Bluish
        "#F1C40F", // Yellow
        "#E91E63", // Pink
        "#9B59B6", // Purple
        "#34495E", // Dark Blue
        "#16A085", // Teal
        "#27AE60", // Green
        "#2980B9", // Blue
        "#8E44AD", // Violet
        "#2C3E50", // Navy
        "#F39C12", // Orange
        "#D35400", // Pumpkin
        "#C0392B", // Red
        "#BDC3C7", // Silver
        "#7F8C8D", // Gray
    ],
    swatches: [
        ["#2ECC71",    "#E67E22",    "#3498DB"],
        ["#9C88FF",    "#4CD137",    "#FFF200"],
        ["#FF9F45",    "#F368E0",    "#FF9FF3"],
        ["#48DBFB",    "#1DD1A1",    "#EE5253"],
        ["#0ABDE3",    "#10AC84",    "#5F27CD"],

        ["#FF5733",    "#33FF57",    "#3357FF"],
        ["#F1C40F",    "#E91E63",    "#9B59B6"],
        ["#34495E",    "#16A085",    "#27AE60"],
        ["#2980B9",    "#8E44AD",    "#2C3E50"],
        ["#F39C12",    "#D35400",    "#C0392B"],

        ["#BDC3C7",    "#7F8C8D",    "#000000"]
    ],

    cacheLoaded: false,
    queryError: '',

    sourceTypes: null,
    userLookup: null,
    customerLookup: null,
    chatLookup: null,
    column_lookup: {},
    columnDefs: [],

    globalVars: null,

    time_values: [
        time.month.every(1),
        time.month.every(2),
        time.month.every(3),
        time.month.every(6),
    ],

    startDate: '2024-02-01T00:09:00.0000000Z',
    endDate: '2024-03-01T00:09:00.0000000Z',
    pageSize: 100,
    presortSize: 1000,
    serverPaginationMode: 1,

    pageSizeRules: [value => (value >= 10 && value <= 1000) || 'Between 10 - 1000 only'],
    presortSizeRules: [value => (value >= 10 && value <= 10000) || 'Between 10 - 10,000 only'],

    buildRequest: function(baseurl, startDate, endDate) {
        const start = new Date(startDate || common.startDate).toISOString();
        const end = new Date(endDate || common.endDate).toISOString();
        return `${baseurl}?StartTime=${start}&EndTime=${end}`;
    },
    byField: function(a, b, field) {
        if (field)
            return a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0;
        else
            return a < b ? -1 : a > b ? 1 : 0;
    },
    transformer: function(rows, nameField, valueField) {
        return Object.fromEntries(rows.map(r => [r[valueField], r[nameField]]));
    },
    remaplookup: function(lookup, valuefield) {
        return Object.fromEntries(Object.keys(lookup).map(a => [a, lookup[a][valuefield]]));
    },

    formatMetric: function(col) {
        if (!col) return 'N/A';

        switch (col.aggFunc) {
            case 'avg':
                switch (col.type) {
                    case 'booleanColumn':
                    case 'percentColumn': return `Percent (%)`;
                    default: return `Average`;
                };
                break;
            case 'sum': return `Total`;

            case 'min':
                switch (col.type) {
                    case 'durationColumn': return `Shortest`;
                    case 'floatColumn':
                    case 'countColumn': return `Minimum`;
                    case 'dateColumn': return 'Earliest';
                }
                return `Shortest`;

            case 'max':
                switch (col.type) {
                    case 'durationColumn': return `Longest`;
                    case 'floatColumn':
                    case 'countColumn': return `Maximum`;
                    case 'dateColumn': return 'Latest';
                }
                return `Longest`;

            case 'cardinality': return `Distinct`;
            case 'value_count': return `Count`;
        }
        return func;
    },
    formatHeader: function(col) {
        if (!col) return 'N/A';

        switch (col.aggFunc) {
            case 'avg':
                switch (col.type) {
                    case 'booleanColumn':
                    case 'percentColumn': return `${col.headerName} (%)`;
                    default: return `Avg ${col.headerName}`;
                }
                break;
            case 'sum': return `Total ${col.headerName}`;

            case 'min':
                switch (col.type) {
                    case 'durationColumn': return `Shortest ${col.headerName}`;
                    case 'floatColumn':
                    case 'countColumn': return `Minimum ${col.headerName}`;
                    case 'dateColumn': return `Earliest ${col.headerName}`;
                }
                return `Shortest ${col.headerName}`;

            case 'max':
                switch (col.type) {
                    case 'durationColumn': return `Longest ${col.headerName}`;
                    case 'floatColumn':
                    case 'countColumn': return `Maximum ${col.headerName}`;
                    case 'dateColumn': return `Latest ${col.headerName}`;
                }
                return `Longest ${col.headerName}`;

            case 'cardinality': return `Distinct ${col.headerName}`;
            case 'value_count': return `Count ${col.headerName}`;
        }
        return col.headerName;
    },

    getAggregateColumnType: function(colId, func, coltype) {
        const coldef = common.column_lookup[colId];

        if (coldef && coldef.MetricAggs.Type == 'bucket_script')
            return coldef.MetricAggs.BucketScript.ResultType;

        switch (func) {
            case 'sum':
                switch (coltype) {
                    case 'booleanColumn': return 'countColumn';
                    default:
                        return coltype;
                }
                break;

            case 'avg':
                switch (coltype) {
                    case 'booleanColumn': return 'percentColumn';
                    case 'countColumn': return 'floatColumn';
                    default:
                        return coltype;
                }
                break;

            case 'cardinality':
            case 'value_count':
                return 'countColumn';

            default:
                return coltype;
        };
    },

    async loadLookup(column) {
        console.log(`loadLookup URL:${column.LookupSettings.LookupURL} requesting...`);
        try {
            const promise = utils.api.get(column.LookupSettings.LookupURL);

            if (!column.expn && column.LookupSettings.ValueType == 'Expression')
                // Create a function to evaluate the expression
                column.expn = new Function(column.LookupSettings.LookupModelAs, 'return ' + column.LookupSettings.ValueExpression + ';');

            if (!column.display_expn && column.LookupSettings.DisplayType == 'Expression')
                column.display_expn = new Function(column.LookupSettings.LookupModelAs, 'return ' + column.LookupSettings.DisplayExpression + ';');

            // 1st - create a lookup table for translating values into display text
            // 2nd - gather all unique values for showing in the list of choices

            const res = await promise;

            console.log(`loadLookup URL:${column.LookupSettings.LookupURL} completed with ${res.length} row(s)`);

            //const values = [];
            const names = [];
            const lookup = {};
            for (let i = 0; i < res.length; i++) {
                const model = res[i];
                let name;
                let value;

                switch (column.LookupSettings.ValueType) {
                    case 'Expression':
                        value = column.expn(model);
                        break;

                    case 'Fieldname':
                    default:
                        value = model[column.LookupSettings.ValueField];
                        break;
                }

                switch (column.LookupSettings.DisplayType) {
                    case 'Expression':
                        name = column.display_expn(model);
                        break;

                    case 'Fieldname':
                        name = model[column.LookupSettings.DisplayField];
                        break;

                    case 'UseValue':
                    default:
                        name = value;
                        break;
                }

                if (name !== undefined && value !== undefined && typeof value !== 'object')
                    lookup[value] = name;

                if (name !== undefined && Array.isArray(value)) {
                    names.push({ name: name, values: value });
                }

                //if (value !== undefined)
                //    values.push(value);
            }

            // This table translates the value into the display text
            column.lookups = lookup;
            // This is for the compound filter and holds the unique names with their one-to-many values
            column.names = names;

            // This list contains all the unique values found in the source data + any already selected values, sorted
            // this.listitems = [...new Set([...values, ...this.selected_items])].toSorted((a, b) => this.byTransformedField(a, b));
        }
        catch (err) {
            console.error(`loadLookup URL:${column.LookupSettings.LookupURL} FAILED: ${err.reasonPhrase || err.message}`);
        }
    },

    padNumber(number, digits) {
        return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
    },
    ISO8601ToLocal(value) {
        // Ex: 2024-08-27T19:18:00.000Z
        const dt = new Date(value);
        
        let year = dt.getFullYear();
        let month = dt.getMonth() + 1;
        let day = dt.getDate();
        let hour = dt.getHours();
        let minute = dt.getMinutes();
        let second = dt.getSeconds();
        let ms = dt.getMilliseconds();

        let local = `${year}-${common.padNumber(month, 2)}-${common.padNumber(day, 2)}T${common.padNumber(hour, 2)}:${common.padNumber(minute, 2)}:${common.padNumber(second, 2)}`;

        if (ms > 0)
            local += `.${common.padNumber(ms, 3)}`;

        return local;
    },
    parseFilter(filter) {
        let match = filter.match(/now([+-]\d+)?([a-zA-Z])?(\/[a-zA-Z])?/);
        return {
            value: match[1] !== undefined ? -parseInt(match[1]) : 0,
            units: match[2] || (match[3].length > 1 ? match[3].substr(1, 1) : match[3]),
            round: !!match[3]
        };
    },
    getWeek: function(date) {
        let yearStart = new Date(date.getFullYear(), 0, 1);
        let today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        let dayOfYear = ((today - yearStart + 1) / 86400000);
        let week = Math.ceil(dayOfYear / 7);
        return week;
    },
    getDateOfWeek: function(w, y) {
        var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week

        return new Date(y, 0, d);
    },
    getRelativeDate: function(unit_type, unit_value, abs_value, round) {
        if (unit_type == 'abs')
            return filters.f_date(new Date(abs_value), 'medium');

        let now = new Date();

        if (unit_type == 'now' || (unit_value === 0 && !round))
            return filters.f_date(new Date(common.getRelativeDateFromFilter('now+1d/d')), 'medium');
            //return 'Now'

        if (round) {
            // Peform rounding first
            // new Date(year, monthIndex, day, hours, minutes, seconds)
            switch (unit_type) {
                case 's': now = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds()); break;
                case 'm': now = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes()); break;
                case 'h': now = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours()); break;
                case 'd': now = new Date(now.getFullYear(), now.getMonth(), now.getDate()); break;
                case 'w': now = common.getDateOfWeek(common.getWeek(now), now.getFullYear()); break;
                case 'M': now = new Date(now.getFullYear(), now.getMonth()); break;
                case 'y': now = new Date(now.getFullYear(), 0); break;
            }
        }

        switch (unit_type) {
            //case 'now': return 'Now';
            case 's': now.setTime(now.getTime() - (unit_value * 1000)); break;
            case 'm': now.setTime(now.getTime() - (unit_value * 60 * 1000)); break;
            case 'h': now.setTime(now.getTime() - (unit_value * 60 * 60 * 1000)); break;
            case 'd': now.setDate(now.getDate() - unit_value); break;
            case 'w': now.setDate(now.getDate() - unit_value * 7); break;
            case 'M': now.setMonth(now.getMonth() - unit_value); break;
            case 'y': now.setMonth(now.getMonth() - unit_value * 12); break;
        }

        return filters.f_date(now, 'medium');
    },
    getRelativeDateFromFilter(filter) {
        if (filter == 'now') {
            //return new Date().toISOString();
            const { value, units, round } = common.parseFilter('now+1d/d');
            return common.getRelativeDate(units, value, null, round);
        }
        else if (filter && filter.substring(0, 3) == 'now') {
            const { value, units, round } = common.parseFilter(filter);
            return common.getRelativeDate(units, value, null, round);
        }
        else if (filter && filter.includes('T'))
            return common.getRelativeDate('abs', null, filter);
        else
            return new Date().toISOString();
    },

    roundToInterval: function (date, interval) {
        return date;

        // Note: this method is primarily for the ag-grid to call when formatting a date column.
        // It was determined to be needed when viewing a report where the browser's timezone
        // isn't the same as the call center's timezone, which causes a discrepency between what
        // OpenSearch returns as the rounded (interval) date/time and how the browser formats
        // the time on the screen. Since all times include a full date and time portion (iso8601),
        // we always have the hours and minutes - and when doing interval reports (by day, month, etc)
        // the date/time OpenSearch returns is rounded to the interval (midnight local time) but
        // returned in UTC: "2024-06-01T00:00:00.000+01:00" for midnight UK Time, which if
        // displayed in Mountain Time, becomes "5/30/2024 6:00 PM" which is in the previous month.
        // By rounding according to the browser's timezone to the nearest day or month (depending on
        // the interval), we hope to compensate for this and display the proper date/time.

        let coeff;
        switch (interval) {
            case '1d': coeff = common.constants.MILLISECONDS_PER_DAY; break;
            case '2d': coeff = 2 * common.constants.MILLISECONDS_PER_DAY; break;
            case '3d': coeff = 3 * common.constants.MILLISECONDS_PER_DAY; break;
            case '180d': coeff = 180 * common.constants.MILLISECONDS_PER_DAY; break;

            case 'week':
                {
                    const week = common.getWeek(date);
                    const year = date.getFullYear();
                    return common.getDateOfWeek(week, year);
                }

            case 'month':
            case 'quarter':
            case 'year':
                {
                    // just round to the nearest month for all three (usually are close enough anyway)
                    const dayOfMonth = date.getDate();
                    const month = date.getMonth();
                    const year = date.getFullYear();
                    if (dayOfMonth > 15)
                        return new Date(year, month + 1, 1);
                    else
                        return new Date(year, month, 1);
                }

            default:
                return date;
        }
        return new Date(Math.round(date.getTime() / coeff) * coeff);
    },

    compareObjects(a, b) {
        if (!a && !b) return true;
        if (!a || !b) return false;
        
        // Grab a distinct list of all members from each object
        const keys = [...new Set([...Object.keys(a), ...Object.keys(b)])];

        // iterate over all members and ensure they exist in both
        for (let key of keys) {
            if (!(key in b) || !(key in a)) return false;

            if (typeof a[key] !== typeof b[key]) return false;

            if (Array.isArray(a[key])) {
                if (a[key].length !== b[key].length) return false;

                for (let i of a[key]) {
                    const same = common.compareObjects(a[key][i], b[key][i]);
                    if (!same) return false;
                }
            }
            else if (typeof a[key] === 'object') {
                const same = common.compareObjects(a[key], b[key]);
                if (!same) return false;
            }
            else if (a[key] !== b[key])
                return false;
        }
        return true;
    },

    async loadSourceTypes() {
        const res = await utils.api.get('Apps/Platform/Schema/AdvancedReports/v1/ReportingSourceTypes'); //  'Apps/Platform/Schema/AdvancedReports/v1/ReportingSources');
        try {
            return res; // .SourceTypes.toSorted((a, b) => common.byField(a, b, 'DisplayName'));
        }
        catch (ex) {
            console.error(ex.message);
            return [];
        }
    },
    async loadCustomerList() {
        if (common.globalVars && common.globalVars.AllCustomers)
            return common.remaplookup(common.globalVars.AllCustomers, 'Name');
        else {
            const res = await utils.api.get('/Apps/ReportStudio/Utils/GetMyCustomerList');
            return common.transformer(res, 'Name', 'CustomerID');
        }
    },
    async loadUserList() {
        if (common.globalVars && common.globalVars.AllUsers)
            return common.remaplookup(common.globalVars.AllUsers, 'FullName');
        else if (common.globalVars && common.globalVars.Users)
            return common.remaplookup(common.globalVars.Users, 'fullname');
        else {
            const res = await utils.api.get('/Apps/ReportStudio/Utils/GetMyUserList');
            return common.transformer(res, 'FullName', 'UserID');
        }
    },
    async loadChatTouchpoints() {
        const res = await utils.api.get('/Apps/Common/ChatTouchPoints');
        return common.transformer(res, 'Name', 'TouchPointID');
    },
    async loadColumns() {
        const columnlist = await utils.api.get('Apps/Platform/Schema/AdvancedReports/v1/Column/ListByID?OnlyMine=true');
        return columnlist;

        // Putting this on ice for now (7/26/24) - nice idea, not needed yet, adds a lot of complexity on the server
        //const morecols = [];
        //for (let c of columnlist) {
        //    if (c.DynamicColumn && c.DynamicColumn.ListAPI) {
        //        const dc = c.DynamicColumn;
        //        const promise = utils.api.get(dc.ListAPI);

        //        // Generate functions to evaluate the dynamic expressions
        //        const title_expn = new Function(dc.ListRowModelAs, `return ${dc.TitleExpression};`);
        //        const srcfld_expn = new Function(dc.ListRowModelAs, `return ${dc.SourceFieldExpression};`);
        //        const colid_expn = new Function(dc.ListRowModelAs, `return ${dc.ColIdExpression};`);

        //        const list = await promise;

        //        for (let x of list) {
        //            morecols.push({
        //                ...c,
        //                Title: title_expn(x),
        //                SourceField: srcfld_expn(x),
        //                ColId: colid_expn(x),
        //            });
        //        }
        //    }
        //}

        //return columnlist.concat(morecols);
    },
    async loadLookupTables(uid, allowcaching) {
        if (allowcaching && common.cacheLoaded) {
            console.log(`_uid:${uid} loadLookupTables already loaded`);
            return;
        }

        if (allowcaching && common.cacheIsLoading) {
            console.log(`_uid:${uid} loadLookupTables awaiting on promise...`);
            common.queryError = await common.myPromise;
            console.log(`_uid:${uid} loadLookupTables promise complete`);
            return;
        }

        common.cacheIsLoading = true;
        common.myPromise = new Promise(function (resolve, reject) {
            common.resolvePromise = resolve;
            common.rejectPromise = reject;
        });

        try {
            common.queryError = '';
            console.log(`_uid:${uid} loadLookupTables beginning...`);

            const [sourcetypes, customerlist, userlist, chatlist, columnlist] = await Promise.all([
                common.loadSourceTypes(),
                common.loadCustomerList(),
                common.loadUserList(),
                common.loadChatTouchpoints(),
                common.loadColumns(),
            ]);

            console.log(`_uid:${uid} loadLookupTables finished initial table loads`);

            common.sourceTypesList = sourcetypes;
            common.sourceTypes = helps.toLookup(sourcetypes, 'SourceName', 'DisplayName');
            common.customerLookup = customerlist;
            common.userLookup = userlist;
            common.chatLookup = chatlist;

            // Grab the sets of columns by usage
            common.column_list = columnlist;
            common.column_lookup = helps.toLookup(columnlist, 'ColId');

            console.log(`_uid:${uid} loadLookupTables Lookups beginning...`);

            const tasks = [];
            for (let c of columnlist) {
                if (c.SourceType == 'textSetColumn') {
                    if (c.MappingTableType == 'LookupAPI') {
                        tasks.push(common.loadLookup(c));
                    }
                    else if (c.MappingTableType == 'LookupList') {
                        c.lookups = helps.toLookup(c.MappingTable, 'Key', 'Value');
                    }
                }
            }

            console.log(`_uid:${uid} loadLookupTables Lookups awaiting ${tasks.length} task(s)...`);

            await Promise.all(tasks);

            console.log(`_uid:${uid} loadLookupTables Lookups completed (${tasks.length} task(s))`);

            common.cacheLoaded = true;
            common.resolvePromise('');
        }
        catch (ex) {
            // If an error occurs, clear the cache flag
            common.cacheLoaded = false;

            console.error(`_uid:${uid} Error in loadLookupTables: ${ex.message || ex.reasonPhrase}`);
            common.queryError = ex.message || ex.reasonPhrase;
            common.rejectPromise(ex.message || ex.reasonPhrase);
        }
        finally {
            common.cacheIsLoading = false;
        }
    },

    //getColumnDefsForSummary() {
    //    let cols = [];
    //    for (let c of common.column_list) {
    //        if (!c.SourceType) continue;

    //        const col = {
    //            colId: c.ColId,
    //            field: c.SourceField,
    //            headerName: c.Title,
    //            type: c.SourceType,
    //            hide: true,
    //            suppressFiltersToolPanel: !c.ColumnUse.Filter,
    //            menuTabs: ['generalMenuTab'],
    //        };

    //        if (c.lookups) {
    //            col.valueFormatter = (v) => {
    //                if (typeof v.value === 'object' && Array.isArray(v.value))
    //                    return v.value.map(a => c.lookups[a] || a).join(', ');
    //                else
    //                    return c.lookups[v.value] || v.value;
    //            }
    //        }
    //        cols.push(col);
    //    }
    //    if (cols)
    //        return cols.toSorted((a, b) => common.byField(a, b, 'headerName'));


    //    // Generate the list of column definitions for ag-grid
    //    let cols = common.column_list.filter(a => a.SourceType).map(a => ({
    //        colId: a.ColId,
    //        field: a.SourceField,
    //        headerName: a.Title,
    //        type: a.SourceType,
    //        hide: true,
    //        suppressFiltersToolPanel: !a.ColumnUse.Filter,
    //        menuTabs: ['generalMenuTab'],
    //    })).toSorted((a, b) => common.byField(a, b, 'headerName'));

    //    for (let i = 0; i < cols.length; i++) {
    //        const row = cols[i];
    //        //if (row.colId == 'dispositions')
    //        //    console.warn(`_uid:${uid} loadLookupTables dispositions type:${row.type}`);

    //        if (row.type == 'textSetColumn' || row.type == 'phoneNumColumn') {
    //            // This is leftover from when we used ag-grid's built-in filter
    //            //row.filterParams = {
    //            //    values: getUniqueColValues(row.colId),
    //            //    refreshValuesOnOpen: true
    //            //};
    //            const c = common.column_lookup[row.colId];

    //            //if (row.colId == 'dispositions')
    //            //    console.warn(`_uid:${uid} loadLookupTables dispositions column_lookup:${(c ? JSON.stringify(c) : 'unassigned')}`);

    //            if (c && c.lookups) {
    //                //if (row.colId == 'dispositions')
    //                //    console.warn(`_uid:${uid} loadLookupTables dispositions assigning valueFormatter`);

    //                row.valueFormatter = (v) => {
    //                    if (typeof v.value === 'object' && Array.isArray(v.value))
    //                        return v.value.map(a => c.lookups[a] || a).join(', ');
    //                    else
    //                        return c.lookups[v.value] || v.value;
    //                }
    //            }
    //        }
    //    }
    //},
    getColumnDefs(source) {
        let cols = [];
        for (let c of common.column_list) {
            if (!c.SourceType || (source && !c.Sources.includes(source))) continue;

            const col = {
                colId: c.ColId,
                field: c.ColumnUse.Computed ? c.ColId : c.SourceField.replace('.keyword', ''), // Strip any fields with the .keyword suffix as this will not match the column in the result set
              //field: c.SourceField,
                headerName: c.Title,
                type: c.SourceType,
                hide: true,
                suppressFiltersToolPanel: !c.ColumnUse.Filter,
                menuTabs: ['generalMenuTab'],
            };

            if (col.type == 'textSetColumn' && c.lookups) {
                col.valueFormatter = (v) => {
                    if (typeof v.value === 'object' && Array.isArray(v.value))
                        return v.value.map(a => c.lookups[a] || a).join(', ');
                    else
                        return c.lookups[v.value] || v.value;
                }
            }
            cols.push(col);
        }
        return cols.toSorted((a, b) => common.byField(a, b, 'headerName'));
    },
};

export default common;