<template>

    <v-list-item-group v-model="selected_items" multiple>

        <v-list-item class="verydense" value="inbound">
            <v-list-item-action>
                <v-icon>
                    {{ selected_items.some(a => a === 'inbound') ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                </v-icon>
            </v-list-item-action>

            <v-list-item-content>
                Inbound
            </v-list-item-content>
        </v-list-item>

        <v-list-item class="verydense" value="outbound">
            <v-list-item-action>
                <v-icon>
                    {{ selected_items.some(a => a === 'outbound') ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                </v-icon>
            </v-list-item-action>

            <v-list-item-content>
                Outbound
            </v-list-item-content>
        </v-list-item>

    </v-list-item-group>

</template>

<script>
    module.exports = {
        data: function () {
            return {
                selected_items: [],
            }
        },
        props: {
            item: Object,
            value: null,
        },
        created() {
            if (this.value && this.value.values)
                this.selected_items = this.value.values;
        },
        watch: {
            selected_items: function (newv) {
                // When all items are selected, we disable this filter
                if (newv.length == 2 || newv.length < 1)
                    this.$emit('change', null);
                else
                    this.$emit('change', {
                        filterType: "set",
                        values: newv
                    });
            }
        },
        methods: {
        }
    }
</script>

<style scoped>
    .verydense {
        max-height: 24px;
        min-height: 24px;
    }

    .mediumdense {
        max-height: 28px;
        min-height: 28px;
    }
</style>