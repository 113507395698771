<template>
    <v-container class="pt-0 px-0">
        <div class="consistentText">
            This date range filter allows you to specify dynamic time periods by setting start and end points 
            relative to today (i.e. filter based on a range that adjusts in relation to the current date and time).
        </div>
        <div class="mt-2 consistentText">
            Examples:
        </div>
        <ul class="consistentText">
            <li>
                Last 7 Days: By setting the From value as "7 days ago" and the To value as "now," the filter will 
                include data from the past 7 days up to the current moment.
            </li>
            <li>
                Last 30 Days: Similarly, setting the From as "30 days ago" and the To as "now" captures data from the past 30 days."
            </li>
        </ul>
        <v-container class="mt-3 customGrid px-0">
            <!-- Row 1 -->
            <v-select outlined dense hide-details
                        style="width:100%;"
                        :items="relative_units"
                        :menu-props="{ offsetY: true }"
                        v-model="units_from"
                        @change="updateReport"
                        background-color="white">
            </v-select>
            <v-text-field v-if="units_from == 'abs' && units_from !== 'now'"
                            style="grid-column: span 2;"
                            outlined dense hide-details
                            v-model="abs_from"
                            type="datetime-local"
                            step="900"
                            label="From"
                            @change="updateReport"
                            background-color="white">
            </v-text-field>
            <v-text-field v-if="units_from != 'abs' && units_from !== 'now'"
                            outlined dense hide-details
                            type="number"
                            label="From"
                            v-model.number="value_from"
                            @input="updateReport"
                            background-color="white">
            </v-text-field>
            <v-menu v-if="false && units_from == 'abs' && units_from !== 'now'" 
                    style="grid-column: span 2"
                    offset-y 
                    :close-on-content-click="false" 
                    :close-on-click="false"
                    >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field outlined dense hide-details
                                    v-bind="attrs" v-on="on"
                                    :value="'12/1/2023'"
                                    type="date"
                                    label="From Date"
                                    _append-icon="mdi-calendar-month"
                                    background-color="white">
                    </v-text-field>
                </template>
                <v-card>
                    <v-card-content style="display: flex; flex-direction: row;">
                        <v-date-picker></v-date-picker>
                    </v-card-content>
                    <v-card-actions>
                        <v-btn>OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
            <v-menu v-if="false && units_from == 'abs' && units_from !== 'now'" 
                    style="grid-column: span 2"
                    offset-y 
                    :close-on-content-click="false" 
                    :close-on-click="false">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field outlined dense hide-details
                                    v-bind="attrs" v-on="on"
                                    :value="'12:00:00 AM'"
                                    type="time"
                                    label="From Time"
                                    _append-icon="mdi-calendar-month"
                                    background-color="white">
                    </v-text-field>
                </template>
                <v-card>
                    <v-card-content style="display: flex; flex-direction: row;">
                        <v-time-picker></v-time-picker>
                    </v-card-content>
                    <v-card-actions>
                        <v-btn>OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
            <v-row v-if="units_from !== 'now' && units_from !== 'abs'" class="ma-0">
                <v-checkbox  dense hide-details 
                            class="my-0 mr-2 pa-0 text-no-wrap"
                            :label="`Round to the ${friendlyFromUnit}`"
                            v-model="round_from"
                            @change="updateReport">
                </v-checkbox>
                <v-tooltip bottom open-delay="50">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-bind="attrs" v-on="on" style="color:lightgrey">mdi-information-slab-circle</v-icon>
                    </template>
                    When checked, the starting value of the range will be rounded down to the nearest {{friendlyFromUnit}}. When unchecked, the starting value of the range will be calculated from now.
                </v-tooltip>
            </v-row>
        
            <!-- Row 2 -->
            <v-select style="grid-row:2; width: 100%;"
                        outlined dense hide-details
                        :items="relative_units"
                        :menu-props="{ offsetY: true }"
                        v-model="units_to"
                        @change="updateReport"
                        background-color="white">
            </v-select>
            <v-text-field v-if="units_to != 'abs' && units_to !== 'now'"
                            style="grid-row:2;"
                            outlined dense hide-details
                            type="number"
                            label="To"
                            v-model.number="value_to"
                            @input="updateReport"
                            background-color="white">
            </v-text-field>
            <v-text-field v-if="units_to == 'abs' && units_to !== 'now'"
                            style="grid-row:2; grid-column:span 2"
                            outlined dense hide-details
                            v-model="abs_to"
                            type="datetime-local"
                            step="900"
                            label="To"
                            @change="updateReport"
                            background-color="white">
            </v-text-field>
            <v-row v-if="units_to !== 'now' && units_to !== 'abs'" class="ma-0" style="grid-row:2;">
                <v-checkbox class="my-0 mr-2 pa-0 text-no-wrap"
                            dense hide-details 
                            :label="`Round to the ${friendlyToUnit}`"
                            v-model="round_to"
                            @change="updateReport">
                </v-checkbox>
                <v-tooltip bottom open-delay="50">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon small v-bind="attrs" v-on="on" style="color:lightgrey">mdi-information-slab-circle</v-icon>
                    </template>
                    When checked, the ending value of the range will be rounded down to the nearest {{friendlyToUnit}}. When unchecked, the ending value of the range will be calculated from now.
                </v-tooltip>
            </v-row>
        </v-container>
        <div class="mt-3 mb-2 consistentText">
            Sample result
        </div>
        <v-row dense style="background-color:#E3EEFE;">
            <v-col cols="5" class="">
                <v-text-field class=""
                                dense 
                                flat
                                solo 
                                hide-details 
                                label="From" 
                                background-color="transparent" 
                                :value="computeCurrentStart">
                    <template v-slot:prepend-inner>
                        <v-sheet class="" color="transparent" style="padding-top:1px; color:var(--v-secondaryText-base);">
                            From:
                        </v-sheet>
                    </template>
                </v-text-field>
            </v-col>
            <v-col cols="5" class="ml-auto">
                <v-text-field class=""
                                dense
                                flat
                                solo 
                                hide-details 
                                label="To" 
                                background-color="transparent"  
                                :value="computeCurrentEnd">
                    <template v-slot:prepend-inner>
                        <v-sheet class="" color="transparent" style="padding-top:1px; color:var(--v-secondaryText-base);">
                            To:
                        </v-sheet>
                    </template>
                </v-text-field>
            </v-col>
        </v-row>

        <v-row dense class="ma-0" v-if="false">
            <v-col cols="6">
                <v-text-field filled rounded dense hide-details label="Local From" :value="computeLocalStart"></v-text-field>
            </v-col>

            <v-col cols="6">
                <v-text-field filled rounded dense hide-details label="Local To" :value="computeLocalEnd"></v-text-field>
            </v-col>
        </v-row>
        <div class="mt-5 consistentText">
            Advantages:
        </div>
        <ul class="consistentText">
            <li>
                Dynamic Adjustment: The range selected updates automatically as time passes, ensuring the filter always reflects latest data.
            </li>
            <li>
                Flexibility: Users can customize the range to suit their needs, whether for recent trends, historical analysis, or specific periods of interest
            </li>
        </ul>
    </v-container>
</template>

<script>
    import utils from '@/Shared/utils.jsx';
    import filters from '@/Shared/filters.js';
    import common from './common.js';

    export default {
        components: {
        },
        data: function () {
            return {
                value_from: '',
                value_to: 0,
                units_from: 'd',
                units_to: 'now',
                round_from: true,
                round_to: true,
                abs_from: '',
                abs_to: '',
                relative_units: [
                    { text: 'Now', value: 'now' },
                    { text: 'Seconds ago', value: 's' },
                    { text: 'Minutes ago', value: 'm' },
                    { text: 'Hours ago', value: 'h' },
                    { text: 'Days ago', value: 'd' },
                    { text: 'Weeks ago', value: 'w' },
                    { text: 'Months ago', value: 'M' },
                    { text: 'Years ago', value: 'y' },
                    { text: 'Absolute', value: 'abs' },
                ],
            }
        },
        props: {
            item: Object,
            value: null,
            EventBus: null,
        },
        created() {
            if (!this.value || Object.keys(this.value).length < 1) {
                // Default values for the date filter
                const now = new Date();
                const year = now.getFullYear();
                let mon = `${now.getMonth()}`; if (mon.length < 2) mon = '0' + mon;
                let day = `${now.getDate()}`; if (day.length < 2) day = '0' + day;

                this.abs_from = `${year}-${mon}-${day}T00:00`; // '2018-06-12T19:30'; // new Date().toISOString();
                this.abs_to = this.abs_from;
            }
            else {
                this.valueChanged(this.value);
            }

            //this.EventBus.$on(`${this.item.ColId}_changed`, this.valueChanged);
        },
        //destroyed() {
        //    this.EventBus.$off(`${this.item.ColId}_changed`, this.valueChanged);
        //},
        watch: {
            abs_to: function (newv) {
                console.warn(`***  abs_to: ${newv} ***`);
            }
        },
        computed: {
            computeCurrentStart: function() {
                return common.getRelativeDate(this.units_from, this.value_from, this.abs_from, this.round_from);
            },
            computeCurrentEnd: function () {
                return common.getRelativeDate(this.units_to, this.value_to, this.abs_to, this.round_to);
            },
            friendlyFromUnit: function() {
                return this.getFriendlyUnit(this.units_from);
            },
            friendlyToUnit: function () {
                return this.getFriendlyUnit(this.units_to);
            },

            computeLocalStart: function () {
                if (this.abs_from)
                    return common.ISO8601ToLocal(this.abs_from);
                return '';
            },
            computeLocalEnd: function () {
                if (this.abs_to)
                    return common.ISO8601ToLocal(this.abs_to);
                return '';
            },
        },
        methods: {
            valueChanged(val) {
                if (val.filter == 'now')
                    this.units_from = 'now';
                else if (val.filter && val.filter.substring(0, 3) == 'now') {
                    const { value, units, round } = common.parseFilter(val.filter);
                    this.value_from = value;
                    this.units_from = units;
                    this.round_from = round;
                }
                else if (val.filter && val.filter.includes('T')) {
                    this.units_from = 'abs';
                    this.abs_from = common.ISO8601ToLocal(val.filter);
                }

                if (val.filterTo == 'now')
                    this.units_to = 'now';
                else if (val.filterTo && val.filterTo.substring(0, 3) == 'now') {
                    const { value, units, round } = common.parseFilter(val.filterTo);
                    this.value_to = value;
                    this.units_to = units;
                    this.round_to = round;
                }
                else if (val.filterTo && val.filterTo.includes('T')) {
                    this.units_to = 'abs';
                    this.abs_to = common.ISO8601ToLocal(val.filterTo);
                }
            },
            updateReport() {
                if (!this.isActive(this.units_from, this.value_from, this.abs_from) || !this.isActive(this.units_to, this.value_to, this.abs_to))
                    this.$emit('change', null);
                else {
                    const rule = {
                        filterType: 'number',
                        type: 'inRange',
                        filter: this.getRelativeQuery(this.units_from, this.value_from, this.abs_from, this.round_from),
                        filterTo: this.getRelativeQuery(this.units_to, this.value_to, this.abs_to, this.round_to)
                    };
                    //console.log(JSON.stringify(rule,null,3));

                    this.$emit('change', rule);
                }
            },
            applyDateRangeHandler(range) {
                console.log('applyDateRangeHandler');
                console.log(range.startDate);
                console.log(range.endDate);
    
                //common.startDate = range.startDate;
                //common.endDate = range.endDate;

                //utils.log(`Saving localStorage(${this.storageKey}-daterange, ${common.startDate} - ${common.endDate})`);
                //this.System.LocalStorage(this.storageKey + '-daterange', `${common.startDate} - ${common.endDate}`);
                //this.Refresh();
            },
            clearDateRangeHandler() {
            },

            formatDate(date){
                return filters.f_date(date, 'medium');
            },
            getFriendlyUnit(unit) {
                switch (unit) {
                    case 's': return 'second';
                    case 'm': return 'minute';
                    case 'h': return 'hour';
                    case 'd': return 'day';
                    case 'w': return 'week';
                    case 'M': return 'month';
                    case 'y': return 'year';
                    default: return '';
                }
            },
            getWeek(date) {
                let yearStart = new Date(date.getFullYear(), 0, 1);
                let today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                let dayOfYear = ((today - yearStart + 1) / 86400000);
                let week = Math.ceil(dayOfYear / 7);
                return week;
            },
            getDateOfWeek(w, y) {
                var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week

                return new Date(y, 0, d);
            },

            isActive(unit_type, unit_value, abs_value) {
                if (unit_type == 'now')
                    return true;
                else if (unit_type == 'abs')
                    return abs_value !== '';
                else
                    return unit_value !== '';
            },
            getRelativeQuery(unit_type, unit_value, abs_value, round) {
                if (unit_type == 'abs')
                    return new Date(abs_value).toISOString();

                let value = 'now';
                
                if (unit_type != 'now' && unit_value > 0)
                    value += `-${unit_value}${unit_type}`;
                else if (unit_type != 'now' && unit_value < 0)
                    value += `+${-unit_value}${unit_type}`;
                
                if (round && unit_type != 'now')
                    value += '/' + unit_type;

                return value;
            },
        }
    }
</script>

<style scoped lang="scss">
    .verydense {
        max-height: 24px;
        min-height: 24px;
    }

    .mediumdense {
        max-height: 28px;
        min-height: 28px;
    }

    .customGrid {
        display: grid; 
        grid-template-columns: 1fr 4em 1fr; 
        grid-template-rows: auto;
        gap: 8px;
        justify-items: start;
        align-items: center;
    }

    .consistentText {
        color: var(--v-black-base) !important; 
        opacity: 0.8 !important; 
        font-size: 14px !important; 
        text-rendering: optimizelegibility !important; 
        letter-spacing:0.1 !important;
    }
</style>